<template lang="pug">
.reset-password.h-full.bg-gradient-to-b.from-blue-900.to-red-700
  div(
    class="w-full md:max-w-xl xl:max-w-md mx-auto h-full d-flex flex-column justify-center"
  )
    v-card.pa-8
      div.my-6.d-flex.justify-center
        svg(width='60', height='60', viewbox='0 0 60 60', fill='none', xmlns='http://www.w3.org/2000/svg')
          path(d='M11.7988 21.6542H15.5629C15.5629 18.2305 18.3805 15.4392 21.8365 15.4392H42C41.6258 14.1308 41.1415 12.8878 40.5252 11.7103H21.8585C16.3113 11.6885 11.7988 16.1589 11.7988 21.6542Z', fill='#B91C1C')
          path(d='M5.89938 21.6542H9.66354C9.66354 15.0031 15.1227 9.57322 21.8585 9.57322H39.2925C38.3899 8.22119 37.3333 6.9564 36.1447 5.84425H21.8365C13.0535 5.84425 5.89938 12.9315 5.89938 21.6542Z', fill='#B91C1C')
          path(d='M0 21.6542H3.76415C3.76415 11.7757 11.8868 3.72897 21.8585 3.72897H33.5472C30.5314 1.65732 26.9654 0.327103 23.1132 0H21.8365C9.7956 0 0 9.70405 0 21.6542Z', fill='#B91C1C')
          path(d='M30.2013 20.3676H26.4371C26.4371 23.7913 23.6195 26.5826 20.1635 26.5826H0C0.374214 27.891 0.858491 29.134 1.47484 30.3115H20.1635C25.6887 30.3115 30.2013 25.8411 30.2013 20.3676Z', fill='#1E3A8A')
          path(d='M36.1006 20.3676H32.3365C32.3365 27.0187 26.8774 32.4486 20.1415 32.4486H2.70757C3.61008 33.8006 4.66668 35.0654 5.85536 36.1776H20.1635C28.9466 36.1558 36.1006 29.0685 36.1006 20.3676Z', fill='#1E3A8A')
          path(d='M42 20.3676H38.2358C38.2358 30.2461 30.1132 38.271 20.1635 38.271H8.4528C11.4685 40.3427 15.0126 41.6729 18.8648 42H20.1415C32.2044 42 42 32.296 42 20.3676Z', fill='#1E3A8A')

      h1.text-2xl.text-gray-800.text-center Password Reset

      v-form( 
        v-model="valid" 
        @submit.prevent="resetPassword"  
      )
        div.mt-6
          v-text-field.mb-2(
            append-icon="mdi-lock"
            label="New Password"
            outlined
            dense
            :rules="[rules.required, rules.isEightCharacters]"
            type="password"
            v-model="newPassword"
            persistent-hint
            hint="Must include at least 8 characters"
          )

          v-text-field(
            append-icon="mdi-lock"
            label="Confirm Password"
            outlined
            dense
            :rules="[rules.required, rules.isMatch(newPassword)]"
            type="password"
            v-model="confirmPassword"
          )
          
        v-btn.mt-6(
          color="primary"
          block
          :disabled="!valid"
          type="submit"
          depressed
        ) Reset

</template>

<script>
import { ref } from '@vue/composition-api'
import { mapActions } from 'vuex'
import formValidations from '@/helpers/formValidations'

export default {
  name: 'ResetPassword',

  setup(props, { root: { $store, $router, $route, $snackError } }) {
    if ($store.getters['auth/isAuthenticated'])
      $router.push('/dashboard')

    const token = $route.query.token
    if (!token) {
      $router.push('/login')
      $snackError('Invalid or Expired reset password link')
    }

    const { valid, rules } = formValidations()
    const newPassword = ref('')
    const confirmPassword = ref('')

    return {
      token,
      valid,
      rules,

      newPassword,
      confirmPassword
    }
  },

  methods: {
    ...mapActions('auth', ['authenticate']),

    async resetPassword () {
      const { AuthManagement } = this.$FeathersVuex.api
      const authManagement = new AuthManagement({
        action: "resetPwdLong",
        value: { 
          token: this.token,
          password: this.newPassword 
        }
      })

      await authManagement.create();
      this.$snackSuccess('Password successfully changed')
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
</style>